/* @link https://utopia.fyi/type/calculator?c=320,16,1.125,1800,20,1.25,6,3,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
  --step--3: clamp(0.7rem, 0.72rem + -0.07vw, 0.64rem);
  --step--2: clamp(0.79rem, 0.79rem + 0.01vw, 0.8rem);
  --step--1: clamp(0.89rem, 0.86rem + 0.12vw, 1rem);
  --step-0: clamp(1rem, 0.95rem + 0.27vw, 1.25rem);
  --step-1: clamp(1.13rem, 1.03rem + 0.47vw, 1.56rem);
  --step-2: clamp(1.27rem, 1.12rem + 0.74vw, 1.95rem);
  --step-3: clamp(1.42rem, 1.2rem + 1.1vw, 2.44rem);
  --step-4: clamp(1.6rem, 1.29rem + 1.57vw, 3.05rem);
  --step-5: clamp(1.8rem, 1.37rem + 2.18vw, 3.82rem);
  --step-6: clamp(2.03rem, 1.43rem + 2.96vw, 4.77rem);
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 1.3;
  @apply text-primary;
}

h1 {
  margin-top: 0;
  font-size: var(--step-6);
}

h2 {
  font-size: var(--step-5);
}

h3 {
  font-size: var(--step-4);
}

h4 {
  font-size: var(--step-3);
}

p {
  font-size: var(--step-0);
  @apply text-secondary;
}

a {
  font-size: var(--step-0);
}

.tiny {
  font-size: var(--step--2);
}

small {
  font-size: var(--step--1);
}

.text-margin {
  margin: 3rem 0 1.38rem;
}
