:root {
  /* UI & surface colors */

  --bg-primary: url(/img/background/white-background.webp);
  --bg-header: #fff;
  --bg-container: #fff;
  --text-primary: #181818;
  --text-secondary: #747474;

  /* Brand colors */
  --button-bg: #6c38ff;
  --button-text: #6c38ff;
}

.dark,
[data-theme="dark"] {
  --bg-primary: url(/img/background/background.webp);
  --bg-header: #0b0b0df2;
  --bg-container: #00000099;
  --text-primary: #fff;
  --text-secondary: #787a90;
  --button-bg: #2400ff;
  --button-text: #fff;
}

.burger {
  position: relative;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: var(--text-primary);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 28px;
  left: 5px;
}
